.f40-form {
}

.f40-form-section {
    border: solid 1px rgb(200, 200, 200);
    margin-bottom: 20px;
}

    .f40-form-section .section-header {
        text-align: center;
        background-color: rgb(200, 200, 200);
        padding: 10px 0px;
        margin-bottom: 10px;
    }

        .f40-form-section .section-header h3 {
            margin: 0px;
        }

.f40-form-row {
    padding: 0px 10px;
    display: flex;
}

.f40-form-group {
    padding-right: 15px;
}

    .f40-form-group .form-label {
        width: 180px;
        vertical-align: bottom;
        padding-top: 0.3em;
    }

    .f40-form-group input.form-control,
    .f40-form-group select.form-select {
        height: 28px;
        width: calc(100% - 180px);
    }

    .f40-form-group.no-flex input.form-control,
    .f40-form-group.no-flex select.form-select {
        width: calc(100%);
    }

    .f40-form-group select.form-select {
        width: calc(100% - 80px);
    }

    .f40-form-group input[type='radio'] {
        width: 20px;
        height: 13px;
    }

    .f40-form-group input[type='checkbox'] {
        width: 16px;
        height: 16px;
    }

    .f40-form-group textarea {
        width: calc(100%) !important;
    }
