body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}

.input-group-sm > .form-select {
  padding-right: 0.25rem;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}

input[type='radio'],
input[type='checkbox'] {
  margin: 4px 0 0;
  line-height: normal;
}

.panel {
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-default {
  border: none;
  border-color: #ddd;
  border-bottom: 1px solid lightgray;
}

.panel-body {
  padding: 15px 0px;
}

.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

@media (min-width: 1500px) {
  .container {
    max-width: 100%;
  }
}

.main-nav li .bi {
  margin-right: 10px;
}

/* Highlighting rules for nav menu items */
.main-nav li a.active,
.main-nav li a.active:hover,
.main-nav li a.active:focus {
  background-color: #4189c7;
  color: white;
}

input[type='checkbox'] {
  margin-right: 8px;
  margin-top: 4px;
}

/* Keep the nav menu independent of scrolling and on top of other items */
.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.address {
  width: 100%;
}

.joined-list {
  width: 600px;
  display: flex;
}

.joined-list .select-list {
  width: calc(300px - 17px);
  height: 8em;
}

.joined-list .select-list select.form-select {
  height: 8em;
  width: 100%;
}

.joined-list .button-column {
  width: 34px;
  margin: 0px 20px;
  padding-top: calc(4em - 34px);
}

.joined-list .button-column > button:first-of-type {
}

.joined-list .button-column > button:last-of-type {
  margin-top: 8px;
}

.btn-dark-blue {
  background-color: rgb(22, 49, 90);
  color: white;
}

.btn-dark-blue:focus,
.btn-dark-blue:hover {
  background-color: rgb(50, 70, 95);
  color: white;
}

.btn-light-blue {
  background-color: rgb(0, 154, 222);
  color: white;
}

.btn-light-blue:hover,
.btn-light-blue:focus {
  background-color: #31b0d5;
  color: white;
}

.btn-orange {
  background-color: rgb(255, 158, 24);
  color: white;
}

.btn-orange:focus,
.btn-orange:hover {
  background-color: rgb(255, 175, 34);
  color: white;
}

.address > * {
  display: inline-block;
  margin-right: 15px;
}

.notes {
  width: 95%;
}

.input {
  width: 36%;
}

.referral {
  padding: 5%;
}

.referralInput {
  width: 100%;
}

.tradeTitle {
  margin-left: 15px;
}

.submit {
  /*margin-bottom: 10%;*/
  display: flex;
  /*justify-content: center;*/
  margin-bottom: 50px;
  margin-top: 50px;
}

.submit > button {
  margin-left: 15px;
}

.missing-file-error {
  border: 2px solid red;
  padding: 20px 10px;
  border-radius: 5px;
}

.file1 {
  display: flex;
  justify-content: center;
  text-align: center;
}

.foot {
  display: inline-flex;
}

.emptyInputWarning {
  display: block;
  color: red;
  position: absolute;
}

#myModal {
  background-color: grey;
}

.p2-back-button {
  position: absolute;
}

.p2-back-button .bi {
  font-size: 12px;
}

.p2-form-card {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  border-radius: 3px;
  width: 25%;
  padding: 10px 30px;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.p2-form-card:hover {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.p2-form-card .bi {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 1.5em;
}

.p2-form-card .card-title {
  font-size: 1.4em;
  font-weight: bold;
}

@media (max-width: 767px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
  body {
    padding-top: 50px;
  }
}

@media (min-width: 768px) {
  /* On small screens, convert the nav menu to a vertical sidebar */
  .main-nav {
    height: 100%;
    width: calc(25% - 20px);
    width: 15%;
  }

  .main-nav .navbar {
    border-radius: 0px;
    border-width: 0px;
    height: 100%;
  }

  .main-nav .navbar-header {
    float: none;
  }

  .main-nav .navbar-collapse {
    border-top: 1px solid #444;
    padding: 0px;
  }

  .main-nav .navbar ul {
    float: none;
  }

  .main-nav .navbar li {
    float: none;
    font-size: 15px;
    margin: 6px;
  }

  .main-nav .navbar li a {
    padding: 10px 16px;
    border-radius: 4px;
  }

  .main-nav .navbar a {
    /* If a menu item's text is too long, truncate it */
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.inputfile + label {
  /*font-size: 1.25em;*/
  /*font-weight: 700;*/

  display: inline-block;
  text-align: center;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #31b0d5;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  /*position: absolute;*/
  z-index: -10;
}

.inputfile + label {
  cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.uploadedButton {
  background-color: aquamarine !important;
}

.manual {
  margin-top: 3%;
}

.mb {
  text-align: center;
}

.gFont {
  font-family: 'Noto Sans HK', sans-serif;
}

.modal-content {
  background-color: #374048;
  color: #fafdf6;
}

.modal-dialog.white .modal-content {
  background-color: white;
  color: black;
}

.modal-dialog.insurance-notice .modal-body .panel-body p {
  padding: 0px 25px;
}
.modal-dialog.insurance-notice .modal-body .panel-default {
  border: 1px solid rgb(221, 221, 221);
}

#unsignedForm {
  float: right;
}

.nowrap {
  white-space: nowrap;
}

.grayInput {
  background-color: gray !important;
  opacity: 0.6;
  cursor: default !important;
}

.text-red {
  color: #dd3e3e;
}

/* ===== SLIDER CHECKBOX ===== */

.switch {
  width: 3em !important;
  height: 1.5em !important;
  position: relative;
  display: inline-block;
}

label.switch {
  margin-bottom: 0px;
}

/* hide the input so the slider span lays over the input */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch .slider:before {
  position: absolute;
  content: '';
  height: 1em;
  width: 1em;
  left: 0.25em;
  top: 0.25em;
  bottom: 0.25em;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider {
  background-color: #2196f3;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(1.5em);
  -ms-transform: translateX(1.5em);
  transform: translateX(1.5em);
}

.switch input:disabled + .slider:before {
  opacity: 0.35;
}

/* Rounded sliders */
.switch .slider.round {
  border-radius: 1em;
}

.switch .slider.round:before {
  border-radius: 50%;
}

/* =========================== */

/* ==== ANIMATED LOADER ==== */
HTML CSSResult Skip Results Iframe EDIT ON .main-item {
  padding: 10px;
  background-color: #fff;
  width: 700px;
}

.background-masker {
  background-color: #fff;
  position: absolute;
}

.btn-divide-left {
  top: 0;
  left: 25%;
  height: 100%;
  width: 5%;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }

  100% {
    background-position: 800px 0;
  }
}

.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 70px;
  position: relative;
}

.shared-dom {
  width: 800px;
  height: 110px;
}

.sub-rect {
  border-radius: 100%;
  width: 70px;
  height: 70px;
  float: left;
  margin: 20px 20px 20px 0;
}

.form-group {
  margin-bottom: 15px;
}

.submitButton {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.input-group {
  flex-wrap: nowrap;
}

.cert-label {
  width: 100%;
}

/* ===== FOOTER ===== */
#footer {
  background-color: #636569;
  padding: 30px 100px;
  color: white;
  /*height: 40px;*/
}

#footer a {
  color: #009ade;
  font-weight: bold;
}
/* ================== */
